"use client";
import PropTypes from "prop-types";
import PhonePrefixSelect from "@/components/common/phone-prefix-select";
import { hasLetter } from "@/utils/functions.client";
import { useState, useEffect } from "react";
import { useSelectCsrfToken, useSelectLocale } from "@/hooks";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import "@/app/[lang]/styles/style.css";
import { DIGITS } from "@/utils/constants";
import { countriesEndpoint } from "@/services/client/endpoint";

export default function LoginMobile({
    phonePrefix = "",
    login = "",
    password = "",
    setLoginDetails = function () {},
    errorMsg = "",
    setErrorMsg = function () {},
    content = {},
    lang = "en",
}) {
    const csrfToken = useSelectCsrfToken();
    const locale = useSelectLocale();
    const [phonePrefixes, setPhonePrefixes] = useState(
        content?.phonePrefixes || []
    );
    const [showPassword, setShowPassword] = useState(false);
    useEffect(() => {
        async function getCountries() {
            const result = await sendInternalPostRequest({
                csrfToken,
                endpoint: countriesEndpoint,
            });
            if (
                Array.isArray(result?.countries) &&
                result?.countries?.length > DIGITS?.ZERO
            ) {
                const allowedPhonePrefixes = result?.countries || [];
                const validPhonePrefixes = [];
                content?.phonePrefixes?.map((phonePrefix) => {
                    const value =
                        phonePrefix?.value?.replace("-", "").trim() || "";
                    if (
                        allowedPhonePrefixes.some(
                            (_country) =>
                                _country?.phonePrefix
                                    ?.replace("-", "")
                                    ?.trim() === value && _country?.isActive
                        )
                    )
                        validPhonePrefixes.push(phonePrefix);
                });
                setPhonePrefixes(validPhonePrefixes);
            }
        }
        if (
            csrfToken?.length > DIGITS?.ZERO &&
            Array.isArray(content?.phonePrefixes) &&
            content?.phonePrefixes?.length > DIGITS?.ZERO
        )
            getCountries();
    }, [csrfToken, content?.phonePrefixes, setPhonePrefixes]);

    return (
        <div className="form__firstchoice">
            <div
                className={
                    "form-group" +
                    (errorMsg?.length > DIGITS?.ZERO &&
                    content?.mobileRequired &&
                    !login
                        ? " error"
                        : "")
                }
                id="form_register_phone"
            >
                <label className="ffv !text-brown" htmlFor="mobile-number">
                    {content?.mobileLabel || "Mobile Number"}
                    {content?.mobileRequired ? <sup>*</sup> : ""}
                </label>
                <div
                    className={`${
                        lang !== "en" ? "flex flex-row-reverse" : ""
                    } input-row`}
                >
                    <div
                        className={`flag-picker ${
                            lang !== "en" ? "!ml-0 !mr-3" : ""
                        }`}
                    >
                        <PhonePrefixSelect
                            phonePrefixes={phonePrefixes || []}
                            countryPrefix={phonePrefix || "964"}
                            setPrefixCode={(value) => {
                                if (errorMsg?.length > DIGITS?.ZERO)
                                    setErrorMsg("");
                                setLoginDetails((state) => ({
                                    ...state,
                                    phonePrefix: value,
                                }));
                            }}
                            lang={lang}
                        />
                    </div>
                    <input
                        className="flag-picker-input digits-iq"
                        type="text"
                        name="phone"
                        pattern="\d*"
                        maxLength="10"
                        placeholder={content?.mobilePlaceholder || ""}
                        value={login}
                        onChange={(e) => {
                            if (errorMsg?.length > DIGITS?.ZERO)
                                setErrorMsg("");
                            let value = e?.target?.value?.trim() || "";
                            value =
                                value.startsWith("0") && phonePrefix !== "225"
                                    ? value.slice(1)
                                    : value;
                            if (!hasLetter(value)) {
                                setLoginDetails((state) => ({
                                    ...state,
                                    login: value,
                                }));
                            }
                        }}
                    />
                </div>
            </div>
            <div
                className={
                    "form-group" +
                    (errorMsg?.length > DIGITS?.ZERO &&
                    content?.passwordRequired &&
                    !password
                        ? " error"
                        : "")
                }
            >
                <label className="ffv !text-brown" htmlFor="passwd_phone">
                    {content?.passwordLabel || "Password"}{" "}
                    {content?.passwordRequired ? <sup>*</sup> : ""}
                </label>
                <input
                    className={
                        "track password " + showPassword
                            ? "password-visible digits-iq"
                            : ""
                    }
                    type={showPassword ? "text" : "password"}
                    name="passwd_phone"
                    placeholder={content?.passwordPlaceholder || ""}
                    value={password}
                    onChange={(e) => {
                        if (errorMsg?.length > DIGITS?.ZERO) setErrorMsg("");
                        setLoginDetails((state) => ({
                            ...state,
                            password: e.target.value,
                        }));
                    }}
                    autoComplete="new-password"
                />
                <div className="ffv error-text">
                    <p>
                        {errorMsg?.length > DIGITS?.ZERO
                            ? content?.mobileLoginError || ""
                            : ""}
                    </p>
                </div>
                <div
                    className={
                        "visibility-button position-absolute " +
                        (["ar", "kd"].includes(locale)
                            ? "right-eye"
                            : "left-eye")
                    }
                    onClick={() => {
                        setShowPassword((state) => !state);
                    }}
                >
                    {showPassword ? (
                        <i className="far fa-eye"></i>
                    ) : (
                        <i className="far fa-eye-slash"></i>
                    )}
                </div>
            </div>
        </div>
    );
}

LoginMobile.propTypes = {
    /**
     * Content data
     */
    content: PropTypes.object.isRequired,
    /**
     * Phone number prefix
     */
    phonePrefix: PropTypes.string.isRequired,
    /**
     * Mobile Number
     */
    login: PropTypes.string.isRequired,
    /**
     * Password
     */
    password: PropTypes.string.isRequired,
    /**
     * Set login details
     */
    setLoginDetails: PropTypes.func.isRequired,
    /**
     * Error message
     */
    errorMsg: PropTypes.string.isRequired,
    /**
     * lang
     */
    lang: PropTypes.string.isRequired,
};
