"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import { useState, useEffect } from "react";
import LoginMobile from "./mobile";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useDispatchSetAuthUser,
  useDispatchSetBalance,
  useDispatchShowLoader,
  useRedirect,
  useSelectAuthUser,
  useSelectCsrfToken,
  useSelectLocale,
  useSelectReadyStore,
  useGetPathToLogin,
  useDispatchShowToaster,
  useSelectCart,
  useSelectGrandDrawDate,
  useDispatchPrevPath,
  useDispatchCurrentPath,
  useSelectPrevPath,
  useSelectCurrentPath,
} from "@/hooks";
import { INVALID_LOGIN, LOGIN_FAILED } from "@/utils/error-constants";
import { loginPath } from "@/services/client/endpoint";
import { trackCartEvents, trackClickEvent } from "@/utils/gtm";
import { useSelector } from "react-redux";
import { generateCartItem, gtmDateFormat } from "@/utils/functions.client";
import {
  isMobile,
  isTablet,
  isMacOs,
  isWindows,
  getUA,
} from "react-device-detect";
import { DIGITS, STRING_CONSTANTS } from "@/utils/constants";

export default function Login({ lang = "en", content = {} }) {
  const [loginDetails, setLoginDetails] = useState({
    type: "MobileNumber",
    phonePrefix: content?.defaultPrefix?.value || "964",
    login: "",
    password: "",
    deviceType: isMobile
      ? STRING_CONSTANTS?.SMART_PHONE
      : isTablet
      ? STRING_CONSTANTS?.TABLET
      : STRING_CONSTANTS?.PC,
    platformType: isWindows
      ? STRING_CONSTANTS?.WINDOWS
      : isMacOs
      ? STRING_CONSTANTS?.MACOS
      : getUA?.toLowerCase()?.includes(STRING_CONSTANTS?.ANDROID?.toLowerCase())
      ? STRING_CONSTANTS?.ANDROID
      : STRING_CONSTANTS?.LINUX,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const readyStore = useSelectReadyStore();
  const authUser = useSelectAuthUser();
  const csrfToken = useSelectCsrfToken();
  const locale = useSelectLocale();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchSetAuthUser = useDispatchSetAuthUser();
  const dispatchSetBalance = useDispatchSetBalance();
  const dispatchShowToaster = useDispatchShowToaster();
  const redirect = useRedirect();
  const cartItems = useSelectCart();
  const getLoginPath = useGetPathToLogin();
  const drawDate = useSelectGrandDrawDate();
  const dispatchprevPath = useDispatchPrevPath();
  const getCurrentPath = useSelectCurrentPath();
  const getPrevPath = useSelectPrevPath();
  const dispatchCurrentPath = useDispatchCurrentPath();
  const isRechargeBalanceNav = sessionStorage.getItem(
    "isRechargeBalanceVisited"
  );

  const gtmData = useSelector((state) => state?.authUser?.data);

  let path = content?.login?.link || "";

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const prevURL = sessionStorage.getItem("prevPath");
    dispatchprevPath(prevURL);
    const currentURL = sessionStorage.getItem("currentPath");
    dispatchCurrentPath(currentURL);
    const { type, phonePrefix, login, password, deviceType, platformType } =
      loginDetails;
    if (
      ((type === "MobileNumber" && phonePrefix) || type) &&
      login?.length > DIGITS?.ZERO &&
      password?.length > DIGITS?.ZERO &&
      !errorMsg &&
      deviceType?.length > DIGITS?.ZERO &&
      platformType?.length > DIGITS?.ZERO
    ) {
      let msg = "";
      setErrorMsg("");
      dispatchShowLoader(true);

      try {
        const response = await sendInternalPostRequest({
          endpoint: loginPath,
          data: loginDetails,
          csrfToken,
        });
        if (response?.success && response?.loggedIn) {
          if (response?.RequirePasswordChange) {
            redirect("/" + locale + "/forgot-password?RequirePasswordChange");
          } else {
            if (response?.profile) {
              dispatchSetAuthUser(response?.profile || {});

              if (prevURL === "/en/cart" || prevURL === "/ar/cart") {
                const itemVal = generateCartItem(
                  cartItems,
                  gtmDateFormat(drawDate || ""),
                  gtmData?.gameID || ""
                );
                trackCartEvents({
                  event: "add_user_info",
                  event_category: "ecommerce",
                  ecommerce: {
                    currency: gtmData?.currency || "",
                    value: itemVal?.value * 3000,
                    user_id:
                      response?.profile?.profile?.playerID || gtmData?.userid,
                    p_previouspagename: gtmData?.previousPage || "",
                    items: itemVal?.item,
                  },
                });
              } else {
                trackClickEvent(
                  "e_bodyinteraction",
                  "non_ecommerce",
                  "login",
                  "",
                  gtmData?.lang || "en",
                  gtmData?.currentPage || "",
                  gtmData?.category || "",
                  gtmData?.subcategory || "",
                  gtmData?.previousPage || "",
                  gtmData?.loggedIn || "",
                  response?.profile?.profile?.playerID || gtmData?.userid
                );
              }
            }
            if (response?.balance?.currencyCode) {
              dispatchSetBalance({
                ...response.balance,
              });
            }

            if (prevURL === "/" + locale + "/checkout/success") {
              redirect("/" + locale);
            } else if (currentURL === prevURL && !isRechargeBalanceNav) {
              redirect("/" + locale);
              // window.history.go(-2);
            } else if (isRechargeBalanceNav) {
              redirect("/" + locale + "/recharge-balance");
            } else {
              redirect(prevURL);
            }
          }
        } else {
          msg = LOGIN_FAILED;
          if (response?.error && typeof response.error === "string") {
            msg = content?.mobileLoginError || INVALID_LOGIN;
          } else if (response?.error && typeof response.error === "object") {
            msg = content?.mobileLoginError || INVALID_LOGIN;
          }
          // setErrorMsg(msg);
          dispatchShowToaster(msg);
        }
      } catch (e) {
        dispatchShowToaster(e.message);
      } finally {
        dispatchShowLoader(false);
      }
    }
  };
  const restrictedPaths = [
    `/${locale}/checkout/success`,
    `/${locale}/checkout/failed`,
    `/${locale}/add-credit-payment-success`,
    `/${locale}/withdraw-succes`,
    `/${locale}/my-account/verification`,
    `/${locale}/my-account/withdraw`,
    `/${locale}/my-account/verification-success`,
    `/${locale}/my-account/verification-rejected`,
    `/${locale}/create-account`,
  ];

  useEffect(() => {
    if (readyStore && authUser?.profile?.dateOfBirth) {
      if (restrictedPaths.includes(getPrevPath)) {
        redirect("/" + locale);
      } else {
        redirect(getPrevPath);
      }

      if (getCurrentPath === getPrevPath) {
        redirect("/" + locale);
        // window.history.go(-2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, path, redirect, readyStore]);

  return (
    <section className="login login__new">
      <div className="container">
        <div className="login__content">
          <div className="form">
            <h1 className="ffv fs24">{content?.title || ""}</h1>
            <form id="form-login" method="POST" onSubmit={handleLoginSubmit}>
              <LoginMobile
                phonePrefix={loginDetails.phonePrefix}
                login={loginDetails.login}
                password={loginDetails.password}
                setLoginDetails={setLoginDetails}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                content={{
                  mobileLabel: content?.mobileLabel || "Mobile Number",
                  mobileRequired: content?.mobileRequired ? true : false,
                  mobilePlaceholder:
                    content?.mobilePlaceholder || "Mobile Number",
                  passwordLabel: content?.passwordLabel || "Password ",
                  passwordRequired: content?.passwordRequired ? true : false,
                  mobileLoginError:
                    content?.mobileLoginError || "Invalid login.",
                  phonePrefixes: content?.phonePrefixes || [],
                }}
                lang={lang}
              />
              <Link
                className="ffv forgot-pw text-orange"
                href={content?.forgotPassword?.link || "#forgot-password"}
              >
                {content?.forgotPassword?.text || ""}
              </Link>
              <button
                className={
                  "btn btn--primary bold-iq" +
                  (errorMsg || !loginDetails?.login || !loginDetails?.password
                    ? " disabled"
                    : "")
                }
                type="submit"
              >
                {content?.login?.text || ""}
              </button>
              <Link
                href={content?.createAccount?.link || "#create-account"}
                className="ffv login__content--link text-orange"
              >
                {content?.createAccount?.text || ""}
              </Link>
              {errorMsg ? (
                <div className="login-alert alert d-block text-justify border-orange">
                  <div className="alert__text">
                    <p className="ffv reqular-iq">
                      {content?.loginErrorMessage || ""}
                    </p>
                    <div className="ffv alert__text--button">
                      <Link
                        className="btn btn--primary bold-iq"
                        href={content?.signUp?.link || "#sign-up"}
                      >
                        {content?.signUp?.text || ""}
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

Login.propTypes = {
  /**
   * Current language
   */
  lang: PropTypes.string.isRequired,
  /**
   * Content data
   */
  content: PropTypes.object.isRequired,
};
